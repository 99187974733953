import store from '@/store'
import {
  mdiClipboardOffOutline,
  mdiClipboardOutline,
  mdiClipboardPlayOutline,
  mdiClipboardCheckOutline,
  mdiClipboardClockOutline,
  mdiClipboardRemoveOutline,
  mdiViewList,
} from '@mdi/js'
import { ref, watch, getCurrentInstance, computed } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';

export default function useProjectsList() {
  const projectListTable = ref([])
  const vm = getCurrentInstance().proxy

  const view = ref('Project')

  const publicPermission = vm.$ability.can('read', 'public')
  const projectPermission = vm.$ability.can('add', 'project')
  const accountingPermission = vm.$ability.can('read', 'accounting') || vm.$ability.can('update', 'accounting') 

  const columns = [
    { text: 'PROJECT', value: 'job', display:publicPermission, role:['Project','Accounting']},
    { text: 'STATUS', value: 'job_status', sortable: false, display:publicPermission, role:['Project','Accounting']},
    { text: 'METHOD/PROJ TYPE', value: 'research_method', display:projectPermission, role:['Project']},
    { text: 'SERVICE', value: 'service_type', display:projectPermission, role:['Project']},
    { text: 'CLIENT', value: 'client', display:publicPermission, role:['Project', 'Accounting']},
    { text: 'CONTACT', value: 'contact', display:accountingPermission, role:['Accounting']},
    { text: 'PO', value: 'po', display:accountingPermission, role:['Accounting']},
    { text: 'BUDGET', value: 'budget', display:accountingPermission, role:['Project','Accounting']},
    { text: 'COUNTRIES', value: 'fields', sortable: false, display:projectPermission, role:['Project']},
    { text: 'SAMPLE', value: 'sample', sortable: false, display:projectPermission, role:['Project']},
    { text: 'START', value: 'field_start', sortable: false, display:projectPermission, role:['Project']},
    { text: 'END', value: 'field_end', sortable: false, display:projectPermission, role:['Project']},
    { text: '1st INV', value: 'first_invoice_billed', display:accountingPermission, role:['Accounting']},
    { text: 'FINAL INV', value: 'final_invoice_billed', display:accountingPermission, role:['Accounting']},
    { text: 'OOP INV', value: 'oop_invoice_billed', display:accountingPermission, role:['Accounting']},
    { text: 'BILLED BY', value: 'billed_by', display:accountingPermission, role:['Accounting']},
    { text: 'WON', value: 'closed_won_date', display:accountingPermission, role:['Accounting']},
    { text: 'REPORT', value: 'report_date', display:publicPermission, role:['Project','Accounting']},
    { text: 'LAST UPD', value: 'updated_at', display:accountingPermission, role:['Accounting']},
    { text: 'INSIGHTS', value: 'insights', display:projectPermission, role:['Project']},
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false, 
      display:projectPermission, 
      role:['Project']
    },
  ]

  
  const tableColumns = computed(() => {
      const userData = JSON.parse(localStorage.getItem('user'))
      const userRole = userData && userData.role ? userData.role.name : null
      if (userRole === 'Accountant') view.value = 'Accounting'
      return columns.filter(column => (column.display && column.role.includes(view.value)))
  })

  const searchQuery = ref('')
  const deptFilter = ref([])
  const groupFilter = ref([])
  const officeFilter = ref([])
  const statusFilter = ref([])
  const totalProjectListTable = ref(0)
  const loading = ref(false)
  const projectFields = ['id', 'job_number', 'job_name', 'countries', 'service_type', 'office', 'project_type', 'ges_bva', 'billing_notes',
                        'job_status', 'currency', 'research_method', 'client', 'contact', 'po', 'budget', 'sample', 'field_start', 'folder_web_url',
                        'field_end', 'first_invoice_billed', 'final_invoice_billed', 'oop_invoice_billed', 'billed_by', 'closed_won_date',
                        'report_date', 'updated_at', 'insights', 'billing_rule', 'first_invoice_amount', 'final_invoice_amount', 'oop_invoice_amount',
                        ]
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE)
  })
  const projectTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  
  const fetchProjects = async (user=null) => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    if (searchQuery.value === null) searchQuery.value = '';
    if (user === null) user = '';
    store
      .dispatch('app-project/fetchProjects', {
        search: searchQuery.value,
        fields: projectFields,
        options: JSON.stringify(options.value),
        page: options.value.page,
        office: officeFilter.value,
        status: statusFilter.value,
        department: deptFilter.value,
        group: groupFilter.value,
        user
        
      })
      .then(response => {
        const { results, count, total_pages } = response.data
        
        projectListTable.value = formatProject(results)
        totalProjectListTable.value = count

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchProjectsStatus = async () => {
    const token = await vm.$msal.getTokenPopup(foundryRequest)
    if (searchQuery.value === null) searchQuery.value = '';
    store
      .dispatch('app-project/fetchProjectsStatus', {
        search: searchQuery.value,
        options: JSON.stringify(options.value),
        page: options.value.page,
        office: officeFilter.value,
        status: statusFilter.value,
        department: deptFilter.value,
        group: groupFilter.value,
      })
      .then(response => {

        projectTotalLocal.value = response.data

      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([deptFilter, groupFilter, officeFilter, statusFilter], () => {
    initProjectList()
    options.value.page = 1
    loading.value = true
    selectedRows.value = []
    fetchProjects()
    fetchProjectsStatus()
  })

  watch([options], () => {
    initProjectList()
    loading.value = true
    selectedRows.value = []
    fetchProjects()
    fetchProjectsStatus()
  })
  

  const formatProject = (projects) => {
    return projects.map((item) => {
      return {
        ...item,
        client: item.client.name || '',
        office: item.office.name || '',
        job_status: item.job_status.name || '',
        service_type: item.service_type.name || '',
        research_method: item.research_method.map(elem => {return elem.name}).join(', '),
        fields: item.countries.map(item => item.country_detail.name).join(", "),
        project_type: item.project_type.name || '',
        job: item.ges_bva ? `${item.job_number} - ${item.job_name} (${item.ges_bva})` : `${item.job_number} - ${item.job_name}`
      }
    })
  }

  const submitSearch = (event, userID=null) => {
    initProjectList()
    if (event) {
      event.preventDefault()
    }
    loading.value = true

    fetchProjects(userID)
  }

  const updateFilter = (attr, obj) => {
    let filter = JSON.parse(localStorage.getItem('project-filter'))
    filter = filter ? filter : {}
      filter[attr] = []
    
      obj.forEach(item => { 
        if (item.value){
          filter[attr].push(item.value)
        } else {
          filter[attr].push(item)
        }
      });

      localStorage.setItem('project-filter', JSON.stringify(filter));
  }

  const initProjectList = () => {
    projectListTable.value = []
  }

  const loadFiltersFromLS = () => {
    if(localStorage.getItem('project-filter')){
      const { dept, group, office, status } = JSON.parse(localStorage.getItem('project-filter'))
      deptFilter.value = dept || ''
      groupFilter.value = group || ''
      officeFilter.value = office || ''
      statusFilter.value = status || ''
    }

  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProjectStatusVariant = status => {
    if (status === 'Pre-Field') return 'primary'
    if (status === 'In-Field') return 'info'
    if (status === 'Post-Field') return 'accent'
    if (status === 'Completed') return 'success'
    if (status === 'On-Hold') return 'warning'
    if (status === 'Cancelled') return 'error'

    return 'primary'
  }

  const resolveProjectTotalIcon = total => {
    if (total === 'Pre-Field') return { icon: mdiClipboardOutline, color: 'primary' }
    if (total === 'In-Field') return { icon: mdiClipboardClockOutline, color: 'info' }
    if (total === 'Post-Field') return { icon: mdiClipboardPlayOutline, color: 'accent' }
    if (total === 'Completed') return { icon: mdiClipboardCheckOutline, color: 'success' }
    if (total === 'On-Hold') return { icon: mdiClipboardRemoveOutline, color: 'warning' }
    if (total === 'Cancelled') return { icon: mdiClipboardOffOutline, color: 'error' }

    return { icon: mdiClipboardOutline, color: 'primary' }
  }

    const downloadProjects = async () => {
        
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch('app-project/downloadProjects', {
          accounting:'True'
        })
        .then(response => {
          
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement('a');
    
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Report - Accounting - ${new Date().toJSON()}.xlsx`);
          document.body.appendChild(fileLink);
    
          fileLink.click();
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            revenueData.value = []
          }
          if (error.response.status === 401) router.push({ name: 'misc-not-authorized' })
        })

    }

  
  return {
    view,
    projectListTable,
    tableColumns,
    searchQuery,
    deptFilter,
    groupFilter,
    officeFilter,
    statusFilter,
    totalProjectListTable,
    loading,
    options,
    projectTotalLocal,
    selectedRows,

    loadFiltersFromLS,
    submitSearch,
    fetchProjects,
    fetchProjectsStatus,
    resolveProjectStatusVariant,
    resolveProjectTotalIcon,
    updateFilter,
    downloadProjects
  }
}
